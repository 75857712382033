<template lang="html">
  <div style="overflow:auto;max-height:500px;">
    <v-list flat>
      <v-list-item v-for="(item,i) in filtered" :key="i">
        <v-list-item-content>
          <v-list-item-title style="overflow-wrap: break-word;text-overflow:initial;overflow:visible;white-space:normal">{{item.nama_aktifitas}}</v-list-item-title>
          <v-list-item-subtitle class="green--text text--lighten-2" v-if="item.angka_kredit != null">Angka Kredit: {{parseFloat(item.angka_kredit).toFixed(3)}}</v-list-item-subtitle>
          <v-list-item-subtitle class="green--text text--lighten-2" v-else>{{item.waktu}} menit</v-list-item-subtitle>
          <!-- <v-list-item-subtitle class="teal--text text--lighten-2" v-if="item.angka_kredit != null && item.level == '1'">Level: Pelaksana/Pemula</v-list-item-subtitle>
          <v-list-item-subtitle class="teal--text text--lighten-2" v-if="item.angka_kredit != null && item.level == '2'">Level: Terampil</v-list-item-subtitle>
          <v-list-item-subtitle class="teal--text text--lighten-2" v-if="item.angka_kredit != null && item.level == '3'">Level: Mahir/Lanjutan</v-list-item-subtitle>
          <v-list-item-subtitle class="teal--text text--lighten-2" v-if="item.angka_kredit != null && item.level == '4'">Level: Penyelia</v-list-item-subtitle>
          <v-list-item-subtitle class="teal--text text--lighten-2" v-if="item.angka_kredit != null && item.level == '5'">Level: Pertama/Ahli Pertama</v-list-item-subtitle>
          <v-list-item-subtitle class="teal--text text--lighten-2" v-if="item.angka_kredit != null && item.level == '6'">Level: Muda/Ahli Muda</v-list-item-subtitle>
          <v-list-item-subtitle class="teal--text text--lighten-2" v-if="item.angka_kredit != null && item.level == '7'">Level: Madya/Ahli Madya</v-list-item-subtitle>
          <v-list-item-subtitle class="teal--text text--lighten-2" v-if="item.angka_kredit != null && item.level == '8'">Level: Utama/Ahli Utama</v-list-item-subtitle> -->
        </v-list-item-content>
        <v-list-item-action>
          <v-btn :class="item.angka_kredit != null ? 'teal' : 'green'" dark @click="dialog(item)">Lapor</v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props:{
    filtered:Array
  },
  methods:{
    dialog(item){
      this.$emit('callDialog', item)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
